import { DataGridPremium } from "@mui/x-data-grid-premium"
import { useEffect, useState } from "react"
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart"
import "../components/styles/AdditionalColumnStyles.css"
import { notify } from "../utils"

const CartItemsGrid = ({ cartItemsToDisplay, handleDeleteItemFromCart, handleCartItemsDisplayed, handleDeleteAllCartItems }) => {
  const [rows, setRows] = useState(cartItemsToDisplay)

  useEffect(() => {
    setRows(cartItemsToDisplay)
  }, [cartItemsToDisplay])

  const handleProcessRowUpdate = (updatedRow, originalRow) => {
    if (updatedRow.quantity <= updatedRow.currentBalance) {
      updatedRow.totalCost = updatedRow.quantity * updatedRow.unitPrice
      const updatedRows = [...rows]
      const rowIndex = rows.findIndex((row) => row.id === updatedRow.id)
      updatedRows[rowIndex] = updatedRow
      setRows(updatedRows)
      handleCartItemsDisplayed(updatedRows)
      return updatedRow
    } else if (updatedRow.quantity > updatedRow.currentBalance) {
      notify("error", "The quantity entered exceeds the amount of inventory available. Please adjust requested amount.")
      return originalRow
    }
  }

  const cartItemColumns = [
    { field: "nationalStockNumber", headerName: "NSN", minWidth: 20, align: "left", headerAlign: "left" },
    { field: "partNumber", headerName: "Part #", minWidth: 100, align: "left", headerAlign: "left" },
    { field: "serialNumber", headerName: "Serial #", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "nomenclature", headerName: "Nomenclature", minWidth: 200, align: "left", headerAlign: "left" },
    { field: "unitOfIssue", headerName: "UI", minWidth: 20, align: "left", headerAlign: "left" },
    { field: "quantity", headerName: "Qty", type: "number", editable: true, minWidth: 20, align: "left", headerAlign: "left" },
    { field: "currentBalance", headerName: "Current Balance", minWidth: 40, type: "number", align: "left", headerAlign: "left" },
    {
      field: "conditionCode",
      headerName: "Condition Code",
      minWidth: 50,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      type: "number",
      minWidth: 80,
      valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
      valueFormatter: ({ value }) =>
        value != null
          ? "$ " +
            Intl.NumberFormat("en-US", {
              maximumFractionsDigits: 2,
              minimumFractionDigits: 2,
            }).format(value)
          : "",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      type: "number",
      minWidth: 80,
      valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
      valueFormatter: ({ value }) =>
        value != null
          ? "$ " +
            Intl.NumberFormat("en-US", {
              maximumFractionsDigits: 2,
              minimumFractionDigits: 2,
            }).format(value)
          : "",
      align: "left",
      headerAlign: "left",
    },
    { field: "location", headerName: "Location", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "propertyNumber", headerName: "Property #", minWidth: 80, align: "left", headerAlign: "left" },
    {
      field: "deleteItem",
      headerName: "Delete Item",
      minWidth: 80,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <div className="actions-buttons">
            <RemoveShoppingCartIcon
              className="delete-btn"
              onClick={() => handleDeleteItemFromCart(params.id)}
            />
          </div>
        )
      },
    },
  ]

  return (
    <DataGridPremium
      columns={cartItemColumns}
      rows={rows}
      disableRowSelectionOnClick
      density="compact"
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row")}
      isCellEditable={(params) => !params.row.serialNumber || params.row.serialNumber === "NA" || params.row.serialNumber === "N/A"}
      processRowUpdate={handleProcessRowUpdate}
      onProcessRowUpdateError={(e) => console.log(e)}
      sx={{
        boxShadow: 3,
        border: "1px solid",
        borderColor: "rgba(16, 80, 117, 0.5)",
        "& .even-row.MuiDataGrid-row": {
          backgroundColor: "rgba(219, 231, 229, 0.35)",
          "&:hover, &.Mui-hovered": {
            backgroundColor: "rgba(84, 187, 253, 0.1)",
            "@media (hover: none)": {
              backgroundColor: "transparent",
            },
          },
        },
      }}
    />
  )
}

export default CartItemsGrid
