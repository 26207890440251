import { useState } from "react"

// ** MUI
import { Box, Grid, IconButton, Modal, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
}

export const FedLogSelectModal = ({ shouldOpen, handleFedLogSelect, fedlogItems }) => {
  return (
    <Modal
      open={shouldOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* CLOSE ICON */}
        <Grid
          container
          spacing={2}
        >
          <IconButton onClick={() => handleFedLogSelect()}>
            <CloseIcon />
          </IconButton>
        </Grid>

        {/* TEXT */}
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          fontFamily={"Merriweather"}
        >
          Multiple entries were found.
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, mb: 2 }}
        >
          Please select the appropriate FEDLOG entry.
        </Typography>

        {/* TABLE */}
        <TableContainer style={{ maxHeight: 500 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell>NIIN</TableCell>
                <TableCell align="right">FSC</TableCell>
                <TableCell align="right">Nomenclature</TableCell>
                <TableCell align="right">Part Number</TableCell>
                <TableCell align="right">CAGE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fedlogItems.map((item) => (
                <TableRow
                  key={item.item_name + item.part_number + item.cage_code}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell padding="checkbox">
                    <Radio
                      onClick={() => handleFedLogSelect(item)}
                      checked={false}
                      inputProps={{ "aria-labelledby": item.part_number }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {item.niin}
                  </TableCell>
                  <TableCell align="right">{item.fsc}</TableCell>
                  <TableCell align="right">{item.item_name}</TableCell>
                  <TableCell align="right">{item.part_number}</TableCell>
                  <TableCell align="right">{item.cage_code}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  )
}
