import { useContext, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// ** MUI
import AddIcon from "@mui/icons-material/Add"
import ArchiveIcon from "@mui/icons-material/Archive"
import EditIcon from "@mui/icons-material/Edit"
import ListIcon from "@mui/icons-material/List"
import { Button, Stack } from "@mui/material"
import { GridActionsCellItem } from "@mui/x-data-grid"

// ** Toastify
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Custom
import AppContext from "../AppContext"
import { CustomStripedGrid } from "../components/CustomStripedGrid"
import { CustomModal } from "../components/CustomModal"
import { useAuth } from "react-oidc-context"
import Header from "../layout/Header"
import LoadingBackdrop, { filterByIdActiveState, filterActiveData, notify } from "../utils"
import "../components/styles/AdditionalColumnStyles.css"

const WarehousesView = ({ leftMenuDrawerOpen }) => {
  const context = useContext(AppContext)
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedDeleteId, setSelectedDeleteId] = useState("")
  const auth = useAuth()
  const location = useLocation()
  const [pathname, setPathname] = useState("")
  const title = "Warehouses"

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (data) {
      setLoaded(true)
    }
  }, [data])

  const fetchData = async () => {
    try {
      let warehousesData = await apiCalls.getRecords("Warehouses")
      const activeData = await filterActiveData(warehousesData.data.value.sort((a, b) => a.buildingNumber.localeCompare(b.buildingNumber)))
      setData(activeData)
      generateBlankWarehousesTemplate()
      setPathname(location.state?.path || window.location.pathname)
    } catch (error) {
      console.error("error", error)
      if (error.response?.status === 401) {
        auth.signinRedirect()
      } else {
        notify("error", "There was a problem loading your warehouses. Please try again.")
      }
    }
  }

  const generateBlankWarehousesTemplate = () => {
    const blankTemplate = context.warehouses.formFields.reduce((acc, currVal) => {
      if (!acc[currVal.field]) {
        acc[currVal.field] = ""
      }
      return acc
    }, {})
    context.blankWarehousesTemplate = blankTemplate
  }

  const handleWhDelete = async (id, title) => {
    try {
      await apiCalls.patchRecord(id, title, "ActiveState", "replace", false)

      const filteredByBoth = await filterByIdActiveState(data, id)
      setData(filteredByBoth)
      setSelectedDeleteId("")
      notify("success", `${title} item archived successfully.`)
    } catch (error) {
      console.error("error1", error)
      // setError(error)
      notify("error", "There was a problem archiving your item. Please try again.")
    }
  }

  const updateColumns = context.warehouses.columns.map((el) => {
    if (el.field === "actions") {
      el.getActions = (params) => {
        if (context.isAdmin) {
          return [
            <Stack showInMenu>
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                component={Link}
                to={`${pathname}/edit/${params.id}`}
                state={{ title: title }}
                showInMenu
              />
              <GridActionsCellItem
                icon={<ArchiveIcon />}
                label="Archive"
                onClick={() => {
                  toggleModal()
                  setSelectedDeleteId(params.row)
                }}
                showInMenu
              />
              <GridActionsCellItem
                icon={<ListIcon />}
                label="Programs"
                component={Link}
                to={`/list_view/${params.id}`}
                state={{ isFromAP: false, pathname: pathname }}
                showInMenu
              />
            </Stack>,
          ]
        } else {
          return [
            <Stack showInMenu={true}>
              <GridActionsCellItem
                icon={<ListIcon />}
                label="Programs"
                component={Link}
                to={`/list_view/${params.id}`}
                state={{ isFromAP: false }}
                showInMenu={true}
              />
            </Stack>,
          ]
        }
      }
    }
    return el
  })

  const toggleModal = () => {
    setIsModalOpen(true)
  }

  const handleConfirmDelete = (response) => {
    if (response) {
      handleWhDelete(selectedDeleteId.id, title)
    }
    setIsModalOpen(false)
  }

  if (loaded) {
    return (
      <>
        <Header title={title} />
        <div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
        {isModalOpen && (
          <CustomModal
            shouldOpen={true}
            handleConfirmDelete={handleConfirmDelete}
            pathname={pathname}
            title="Confirm Warehouse Archive"
            reference={selectedDeleteId.buildingNumber}
          />
        )}
        {context.isAdmin && (
          <div className="basic-btn-container">
            <Link
              to={`${pathname}/new`}
              state={{ pathname: pathname, title: title }}
            >
              <Button
                sx={{ fontSize: ".87rem", color: "black", fontWeight: "400" }}
                variant="contained"
                startIcon={<AddIcon />}
              >
                Add Warehouse
              </Button>
            </Link>
          </div>
        )}
        <CustomStripedGrid
          pathname={pathname}
          data={data}
          columns={updateColumns}
          title={title}
        />
      </>
    )
  } else {
    return <LoadingBackdrop leftMenuDrawerOpen={leftMenuDrawerOpen} />
  }
}

export default WarehousesView
