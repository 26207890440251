import { useContext, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// ** MUI
import { Box, Checkbox, FormControl, FormGroup, FormControlLabel, IconButton, Stack, Tooltip } from "@mui/material"
import { GridLogicOperator } from "@mui/x-data-grid"
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Styles
import "../components/styles/AdditionalColumnStyles.css"

// ** Context
import AppContext from "../AppContext"

// ** Custom
import { CustomStripedGrid } from "./CustomStripedGrid"
import { CustomModal } from "./CustomModal"
import { notify, getProgramName, getTimezoneFormattedDate, permissionCheck, SetTimeAndOffset } from "../utils"

const TransferRequestTabView = ({ allTransferTransactionData, handleTransferRequestCancel, selectedGlobalProgramId }) => {
  let context = useContext(AppContext)
  const location = useLocation()
  const notPermitted = permissionCheck(["Government Customer", "Program Customer", "Inventory Manager"], context.usersRoles)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [formToCancel, setFormToCancel] = useState("")
  const pathname = location.state?.path || window.location.pathname
  const [filteredData, setFilteredData] = useState("")
  const [filterModel, setFilterModel] = useState()
  const [statuses, setStatuses] = useState({
    PENDING: false,
    COMPLETE: false,
    CANCELLED: false,
  })

  const handleChange = (event) => {
    setStatuses({
      ...statuses,
      [event.target.name]: event.target.checked,
    })
  }

  const { PENDING, COMPLETE, CANCELLED } = statuses

  useEffect(() => {
    let items = Object.entries(statuses)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => {
        return {
          id: `${key}:${value}`,
          field: "status",
          operator: "equals",
          value: key,
        }
      })

    setFilterModel({ items, logicOperator: GridLogicOperator.Or })
  }, [statuses])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      let filteredTransfers
      /*
       * NOTE: If the program is Receiving, show all transfers that are from Receiving to any program.
       * Receiving needs to be able to see the document numbers that are generated.
       */
      if (getProgramName(selectedGlobalProgramId, context.allProgramsData) === "Receiving")
        filteredTransfers = [...allTransferTransactionData].filter(
          (tt) => tt.fromProgramName === "Receiving" || tt.toProgramId === selectedGlobalProgramId
        )
      else filteredTransfers = [...allTransferTransactionData].filter((tt) => tt.toProgramId === selectedGlobalProgramId)
      setFilteredData(filteredTransfers)
    } catch (error) {
      console.error("error", error)
      notify("error", "There was a problem loading the transfer requests. Please try again.")
    }
  }

  // Highlight those rows which have a 'PENDING' status.
  const handleRowHighlight = (params) => {
    let rowClass = params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
    let pending = "pending"
    let updatedRowClass
    if (params.row.status === "PENDING") {
      updatedRowClass = `${rowClass} ${pending}`
      return updatedRowClass
    } else return rowClass
  }

  const toggleCancelModal = () => {
    setIsCancelModalOpen(true)
  }

  const handleCancelForm = async (response, reason) => {
    let operation = "replace"
    if (response) {
      let patchChanges = [
        { path: "Status", op: `${operation}`, value: "CANCELLED" },
        { path: "CancelSignature", op: `${operation}`, value: `${context.username}` },
        { path: "CancelDate", op: `${operation}`, value: `${SetTimeAndOffset(new Date().toISOString())}` },
        { path: "CancelReason", op: `${operation}`, value: `${reason}` },
      ]

      await handleTransferRequestCancel(formToCancel, patchChanges)
    }

    setFormToCancel("")
    setIsCancelModalOpen(false)
  }

  const columns = [
    {
      field: "documentNumber",
      headerName: "Document Number",
      minWidth: 175,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="editable">
            <Link
              to={`/transfer_request_edit/${params.id}`}
              state={{ params: params.row }}
            >
              {params.value}
            </Link>
          </div>
        )
      },
    },
    {
      field: "initiationDate",
      headerName: "Initiation Date",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        return getTimezoneFormattedDate(params?.value, false)
      },
    },
    { field: "initiator", headerName: "Initiator", minWidth: 150, flex: 1 },
    { field: "fromProgramName", headerName: "From Program", mindWidth: 150, flex: 1 },
    { field: "status", headerName: "Status", minWidth: 100, flex: 1 },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        return getTimezoneFormattedDate(params?.value, true)
      },
    },
    {
      field: "actions",
      headerName: "",
      minWidth: 50,
      align: "center",
      renderCell: (params) => {
        if (params.row.status !== "CANCELLED" && params.row.status !== "COMPLETE") {
          return (
            <Stack direction="row">
              <Tooltip
                title="Cancel"
                arrow
                placement="left"
              >
                <IconButton
                  color="error"
                  onClick={() => {
                    setFormToCancel(params.row)
                    toggleCancelModal()
                  }}
                >
                  <DisabledByDefaultRoundedIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )
        }
        return null
      },
    },
  ]

  return (
    <>
      {isCancelModalOpen && (
        <CustomModal
          shouldOpen={true}
          handleConfirmDelete={handleCancelForm}
          pathname={pathname}
          title="Cancel Transfer Request"
          reference={formToCancel.documentNumber}
        />
      )}
      <Box>
        <div style={{ marginBottom: "15px" }}>
          <FormControl component="fieldset">
            <FormGroup
              aria-label="position"
              row
            >
              <FormControlLabel
                value="PENDING"
                control={
                  <Checkbox
                    checked={PENDING}
                    onChange={handleChange}
                    name="PENDING"
                  />
                }
                label="Pending"
                labelPlacement="end"
              />
              <FormControlLabel
                value="COMPLETE"
                control={
                  <Checkbox
                    checked={COMPLETE}
                    onChange={handleChange}
                    name="COMPLETE"
                  />
                }
                label="Complete"
                labelPlacement="end"
              />
              <FormControlLabel
                value="CANCELLED"
                control={
                  <Checkbox
                    checked={CANCELLED}
                    onChange={handleChange}
                    name="CANCELLED"
                  />
                }
                label="Cancelled"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </div>
        <CustomStripedGrid
          data={filteredData}
          columns={columns}
          loading={!filteredData}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
          rowCustomization={handleRowHighlight}
          disableColumnSelector={true}
          initialState={{
            columns: {
              columnVisibilityModel: {
                actions: notPermitted ? false : true,
              },
            },
            sorting: {
              sortModel: [{ field: "initiationDate", sort: "desc" }],
            },
          }}
        />
      </Box>
    </>
  )
}

export default TransferRequestTabView
