import axios from "axios"

// ** DYNAMIC
const getRecords = async (entity) => {
  return axios.get(`/api/${entity}`)
}

const getById = async (id, entity, query) => {
  return axios.get(`/api/${entity}/${id}/${query}`)
}

const patchRecord = async (id, entity, property, operation, value) => {
  return axios.patch(`/api/${entity}/${id}`, [
    {
      path: `${property}`,
      op: `${operation}`,
      value: `${value}`,
    },
  ])
}

// For doing multiple operations on an object.
const patchRecordMultiple = async (id, entity, changes) => {
  return axios.patch(`/api/${entity}/${id}`, changes)
}

const postRecord = async (form, entity) => {
  return axios.post(`/api/${entity}`, form)
}

const putRecord = async (form, entity, id) => {
  return axios.put(`/api/${entity}/${id}`, form)
}

// Inventory Record PUT For Pending Changes
const putPendingRecordChanges = async (form, entity, id) => {
  return axios.put(`/api/${entity}/PendingChanges/${id}`, form)
}

const deleteRecord = async (id, entity) => {
  return axios.delete(`/api/${entity}/${id}`)
}

// ** OTHER
const getManagementRecords = async (query) => {
  return axios.get(`/api/ManagementRecords/${query}`)
}

// For fetching collections of WRTs and Transfers.
const getCollectionByTransactionId = async (id, entity, collection) => {
  return axios.get(`/api/Logs/${entity}/${id}/${collection}`)
}

// For deleting items in collections for WRTs and Transfers.
const deleteItemsByTransactionId = async (id, entity, collection) => {
  return axios.delete(`/api/${entity}/${id}/${collection}`)
}

/*
 * For removing a relationship between an inventory record and a document.
 * Alse used for other object types (i.e., WRTs) and documents.
 */
const deleteAttachmentRecordByIds = async (recordId, documentId, entity) => {
  return axios.delete(`/api/${entity === "inventoryRecord" ? "AttachmentRecords" : "BaseAttachmentRecords"}/${recordId}/${documentId}`)
}

// Validates the values for a type of record.
const validateRecord = async (entity, body) => {
  return axios.post(`/api/${entity}/Validate`, body)
}

// Monthly Percentage Of Inventory Report
const getMonthlyPercentage = async (apId, percent) => {
  return axios.get(`/api/InventoryRecords/${apId}/MonthlyPercentage/${percent}`)
}

// Get next COSIS Due Date based on COSIS Complete Date parameter.
const getNewCosisDueDate = async (warehouseId, date) => {
  return axios.get(`/api/InventoryRecords/Warehouse/${warehouseId}/Date/${date}`)
}

// For Current/Pending Balance
const filterRecordsById = async (id) => {
  return axios.get(`/api/InventoryRecords?%24filter=id%20eq%20${id}`)
}

// For checking duplicate entries by P/N and NSN.
const filterForDuplicateNumbers = async (partNumber, nsn) => {
  return axios.get(`/api/InventoryRecords?%24filter=partNumber%20eq%20%27${partNumber}%27%20and%20nationalStockNumber%20eq%20%27${nsn}%27`)
}

const filterInventoryRecordsByWarehouseId = async (whId) => {
  return axios.get(`/api/InventoryRecords?%24filter=ManagementRecordWarehouseId%20eq%20${whId}`)
}

const filterInventoryRecordsByAPandWhIds = async (apId, whId) => {
  return axios.get(`/api/InventoryRecords?%24filter=ManagementRecordArmyProgramId%20eq%20${apId}%20and%20ManagementRecordWarehouseId%20eq%20${whId}`)
}

// Combined filters for COSIS Report and AP and WH.
const filterRecordsByCosisConditions = async (cosisConditions) => {
  return axios.post(`/api/InventoryRecords/CosisFilter`, cosisConditions)
}

const filterRecordsByWHandCOSIS = async (whId, date, COSISReport, comparison) => {
  return axios.get(
    `/api/InventoryRecords?%24filter=ManagementRecordWarehouseId%20eq%20${whId}%20and%20${COSISReport}%20${comparison}%20${date}%20or%20ManagementRecordWarehouseId%20eq%20${whId}%20and%20${COSISReport}%20eq%20${date}`
  )
}

const filterRecordsByAPandWHandCOSIS = async (apId, whId, date, COSISReport, comparison) => {
  return axios.get(
    `/api/InventoryRecords?%24filter=ManagementRecordArmyProgramId%20eq%20${apId}%20and%20ManagementRecordWarehouseId%20eq%20${whId}%20and%20${COSISReport}%20${comparison}%20${date}%20or%20ManagementRecordArmyProgramId%20eq%20${apId}%20and%20ManagementRecordWarehouseId%20eq%20${whId}%20and%20${COSISReport}%20eq%20${date}`
  )
}

// Dynamic COSIS Report Filter
const dynamicFilterForCOSISReports = async (date, COSISReport, comparison) => {
  return axios.get(`/api/InventoryRecords?%24filter=${COSISReport}%20${comparison}%20${date}%20or%20${COSISReport}%20eq%20${date}`)
}

// Sends email notifications for processes.
const sendEmailNotifications = async (emailBody) => {
  return axios.post(`/api/Email/Send`, emailBody)
}

// ** LIST VIEWS
const getWarehousesListByArmyProgramId = async (id) => {
  return axios.get(`/api/ArmyPrograms?%24filter=id%20eq%20${id}&%24expand=WarehouseList%28%24expand%3DWarehouse%29`)
}

const getArmyProgramsListByWarehouseId = async (id) => {
  return axios.get(`/api/Warehouses?%24filter=id%20eq%20${id}&%24expand=ArmyProgramList%28%24expand%3DArmyProgram%29`)
}

const getHistoricChangesByInventoryRecordId = async (id) => {
  return axios.get(`/api/Logs/InventoryRecord/${id}/Differences`)
}

// ** IMPORT-RELATED
// Either validates data in a file or adds a file depending on the action parameter.
const actionOnInventoryRecords = async (action, csv) => {
  return axios.post(`/api/InventoryRecords/${action}`, csv, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  })
}

const postCSVFile = async (csv, apId) => {
  return axios.post(`/api/InventoryRecords/CsvUpload/${apId}`, csv, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  })
}

// ** EXCEL REPORTS & LOGS GENERATION
const generateExcelFile = async (name) => {
  return axios.get(`/api/ExcelLogs/${name}/Generate`, {
    responseType: "blob",
  })
}

// ** FEDLOG
const fedLogLookup = async (field, value) => {
  let query
  if (field === "nationalItemIdentificationNumber" || field === "partNumber") {
    field = field === "nationalItemIdentificationNumber" ? "niin" : "part_number"
    query = `${field}=eq.${value}`
  } else {
    // Get the FSC and NIIN values that make up the NSN.
    let fsc = value.slice(0, 4)
    let niin = value.slice(-9)
    query = `fsc=eq.${fsc}&niin=eq.${niin}`
  }
  return axios.get(`https://fedlog.sigmatech.dev/inventory?${query}`, {
    headers: {
      Accept: "application/json",
      Authorization: "", // Strip bearer token for public API.
    },
  })
}

// ** HANGFIRE SERVICES
const postRecordWithHangfire = async (form, entity) => {
  return axios.post(`/api/${entity}/Records`, form)
}

const uploadCSVWithHangfire = async (form) => {
  return axios.post(`/api/HangFireJobs/CsvUpload`, form)
}

const updateRecordsWithHangfire = async (form) => {
  return axios.post(`/api/HangFireJobs/CsvUpload/UpdateInventory`, form)
}

// ** KEYCLOAK ADMIN SERVICES
const getKeycloakUsers = async () => {
  return axios.get(`/api/Keycloak/Users`)
}

const getKeycloakPrograms = async () => {
  return axios.get(`/api/Keycloak/Groups`)
}

const getKeycloakRoles = async () => {
  return axios.get(`/api/Keycloak/Clients/Roles`)
}

const getUsersRoles = async (id) => {
  return axios.get(`/api/Keycloak/Users/${id}/Roles`)
}

const getUsersPrograms = async (id) => {
  return axios.get(`/api/Keycloak/Users/${id}/Groups`)
}

const postRolesToUser = async (id, roles) => {
  return axios.post(`/api/Keycloak/Users/${id}/Roles`, roles)
}

const postKeycloakGroup = async (program) => {
  return axios.post(`/api/Keycloak/Groups`, program)
}

const putKeycloakUser = async (id, user) => {
  return axios.put(`/api/Keycloak/Users/${id}`, user)
}

const setUserToProgram = async (id, programId) => {
  return axios.put(`/api/Keycloak/Users/${id}/Groups/${programId}`)
}

const deleteKeycloakUser = async (id) => {
  return axios.delete(`/api/Keycloak/Users/${id}`)
}

const deleteRolesFromUser = async (id, roles) => {
  return axios.delete(`/api/Keycloak/Users/${id}/Roles`, { data: roles })
}

const removeUserFromProgram = async (id, programId) => {
  return axios.delete(`/api/Keycloak/Users/${id}/Groups/${programId}`)
}

// Filter for created Program to set Id attribute in Keycloak Group object.
const getArmyProgramByName = async (name) => {
  return axios.get(`/api/ArmyPrograms?%24filter=name%20eq%20%27${name}%27`)
}

// ** AWS S3 Services
const getObjects = async (bucketName, folderName, objectName) => {
  return axios.get(`/api/AmazonS3Services/Bucket/${bucketName}/${folderName}/${objectName}/Objects`)
}

const downloadFile = async (bucketName, firstObjectKeyName, secondObjectKeyName, thirdObjectKeyName) => {
  return axios.get(`/api/AmazonS3Services/Bucket/${bucketName}/${firstObjectKeyName}/${secondObjectKeyName}/${thirdObjectKeyName}/Download`, {
    responseType: "blob",
  })
}

const copyFile = async (bucketName, sourceObjectKeyName, destFirstObjectKeyName, destSecondObjectKeyName, objectKeyName) => {
  return axios.put(
    `/api/AmazonS3Services/Bucket/${bucketName}/Source/${sourceObjectKeyName}/Destination/${destFirstObjectKeyName}/${destSecondObjectKeyName}/Copy/${objectKeyName}`
  )
}

const putFile = async (basicRequest) => {
  return axios.put(`/api/AmazonS3Services/Bucket/awims/File`, basicRequest, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  })
}

const putFolder = async (basicRequest) => {
  return axios.put(`/api/AmazonS3Services/Bucket/${basicRequest.bucketName}/Folder`, basicRequest)
}

const deleteFile = async (bucketName, firstObjectKeyName, secondObjectKeyName, thirdObjectKeyName) => {
  return axios.delete(`/api/AmazonS3Services/Bucket/${bucketName}/${firstObjectKeyName}/${secondObjectKeyName}/${thirdObjectKeyName}/File`)
}

const apiCalls = {
  getRecords,
  getById,
  patchRecord,
  patchRecordMultiple,
  postRecord,
  putRecord,
  putPendingRecordChanges,
  deleteRecord,
  getManagementRecords,
  getCollectionByTransactionId,
  deleteItemsByTransactionId,
  deleteAttachmentRecordByIds,
  validateRecord,
  getMonthlyPercentage,
  getNewCosisDueDate,
  filterRecordsById,
  filterForDuplicateNumbers,
  filterInventoryRecordsByWarehouseId,
  filterInventoryRecordsByAPandWhIds,
  filterRecordsByCosisConditions,
  filterRecordsByWHandCOSIS,
  filterRecordsByAPandWHandCOSIS,
  dynamicFilterForCOSISReports,
  sendEmailNotifications,
  getWarehousesListByArmyProgramId,
  getArmyProgramsListByWarehouseId,
  getHistoricChangesByInventoryRecordId,
  actionOnInventoryRecords,
  postCSVFile,
  generateExcelFile,
  fedLogLookup,
  postRecordWithHangfire,
  uploadCSVWithHangfire,
  updateRecordsWithHangfire,
  getKeycloakUsers,
  getKeycloakPrograms,
  getKeycloakRoles,
  getUsersRoles,
  getUsersPrograms,
  postRolesToUser,
  postKeycloakGroup,
  putKeycloakUser,
  setUserToProgram,
  deleteKeycloakUser,
  deleteRolesFromUser,
  removeUserFromProgram,
  getArmyProgramByName,
  getObjects,
  downloadFile,
  copyFile,
  putFile,
  putFolder,
  deleteFile,
}

export default apiCalls
