import { useState, useContext } from "react"
import { v4 as uuidv4 } from "uuid"

// ** MUI
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** Context
import AppContext from "../AppContext"

// ** Styles
import "./styles/CustomFormStyles.css"
import "./styles/ModalStyles.css"

// ** Custom
import { numberInUOM } from "../context/variables"

export const FormDialog = ({ shouldOpen, onClose, invFormFields, handleMassInventoryUpdate }) => {
  let context = useContext(AppContext)
  const [fieldToEdit, setFieldToEdit] = useState("")
  const [updatedValue, setUpdatedValue] = useState("")
  const [error, setError] = useState("")

  let filterOutInvFF = invFormFields.filter((el) => {
    let conditionToMeet = el.field === "managementRecordArmyProgramId" || el.field === "managementRecordWarehouseId" || el.field === "documents"

    /*
     * NOTE: Inventory Managers should only be able to make mass edits to the AESIP and Asset Id fields.
     * Team Leads can edit everything except the Asset ID and CC fields.
     */
    if (context.isInventoryManager && !context.isAdmin) {
      return el.field === "armyEnterpriseSystemIntegrationProgramSerializationIndicator" || el.field === "assetIdentification" ? true : false
    } else if (context.isTL && !context.isAdmin) {
      return el.field !== "assetIdentification" && el.field !== "conditionCode" && !conditionToMeet ? true : false
    } else {
      if (conditionToMeet) return false
      else return true
    }
  })

  let inOrderInvFormFields = filterOutInvFF.sort((a, b) => a.title.localeCompare(b.title))

  const formFieldOptions = inOrderInvFormFields.map((el) => {
    return (
      <MenuItem
        key={uuidv4()}
        value={el}
      >
        {el.title}
      </MenuItem>
    )
  })

  const createEnumSelectOptions = (options) => {
    return Object.keys(options).map((el) => {
      let hasNumber = el === "FIFTY_NINE" || el === "SIXTY" || el === "B_SEVEN" || el === "Y_FOUR"
      return (
        <MenuItem
          key={uuidv4()}
          value={el}
        >
          {hasNumber ? numberInUOM[el] : el}
        </MenuItem>
      )
    })
  }

  const createPackagingDeficiencyOptions = (deficiencyData) => {
    return deficiencyData
      .sort((a, b) => a.number - b.number)
      .map((el) => {
        return (
          <MenuItem
            key={el.id}
            value={el.number}
            style={{ whiteSpace: "normal", textAlign: "left" }}
          >
            {`${el.number}: ${el.description}`}
          </MenuItem>
        )
      })
  }

  const customHandleChange = (e) => {
    setFieldToEdit(e.target.value)
    setUpdatedValue("")
    setError("")
  }

  const determineInputToDisplay = (fieldToEdit) => {
    let is2DecimalPlaces = fieldToEdit.field === "exchangePrice" || fieldToEdit.field === "totalCost" || fieldToEdit.field === "unitPrice"
    let isQty = fieldToEdit.field === "quantity"
    let numOfDecimals = parseInt(isQty ? 3 : 2)

    let currencyInputProps = { startAdornment: <InputAdornment position="start">$</InputAdornment> }
    if (is2DecimalPlaces || isQty) {
      return (
        <FormControl
          error={error ? true : false}
          fullWidth
        >
          <TextField
            autoFocus
            required
            error={error ? true : false}
            margin="dense"
            id="updated-value"
            name="updatedValue"
            label="New Value"
            InputProps={is2DecimalPlaces ? currencyInputProps : null}
            placeholder={isQty ? "0.000" : "0.00"}
            value={updatedValue}
            type="text"
            onBlur={(e) => {
              let inputVar = e.target.value
              let splinPut = inputVar.split(".")
              if (isQty && splinPut[1]?.length > 3) {
                setError("Quantity field is limited to 3 decimal places.")
              } else if (isQty && splinPut[1]?.length === 3) {
                setError("")
              }
              if (is2DecimalPlaces && splinPut[1]?.length > 2) {
                setError("Currency fields are limited to 2 decimal places.")
              } else if (is2DecimalPlaces && splinPut[1]?.length === 2) {
                setError("")
              }
            }}
            onChange={(e) => setUpdatedValue(e.target.value)}
            variant="standard"
          />
        </FormControl>
      )
    }
    if (fieldToEdit.type === "date") {
      return (
        <FormControl fullWidth>
          <InputLabel shrink>New Value</InputLabel>
          <OutlinedInput
            id="updated-value"
            size="small"
            margin="dense"
            autoFocus
            required
            notched
            error={error ? true : false}
            label="New Value"
            type={fieldToEdit.type}
            value={updatedValue}
            onChange={(e) => setUpdatedValue(e.target.value)}
            variant="standard"
          />
        </FormControl>
      )
    }
    if (fieldToEdit.type === "select") {
      return (
        <FormControl fullWidth>
          <InputLabel
            shrink
            id="updated-value"
          >
            New Value
          </InputLabel>
          <Select
            notched
            autoFocus
            id="updated-value"
            name="updatedValue"
            label="New Value"
            error={error ? true : false}
            size="small"
            margin="dense"
            labelId="new-value-select"
            value={updatedValue}
            type={fieldToEdit.type}
            onChange={(e) => setUpdatedValue(e.target.value)}
            variant="standard"
            MenuProps={{ PaperProps: { sx: { maxHeight: 150, maxWidth: 150 } } }}
          >
            <MenuItem
              key="select-updated-value"
              value=""
              disabled
            >
              Select Updated Value
            </MenuItem>
            {fieldToEdit.field === "packagingDeficiency"
              ? createPackagingDeficiencyOptions(context.deficiencyData)
              : createEnumSelectOptions(fieldToEdit.options)}
          </Select>
        </FormControl>
      )
    }
    if (((fieldToEdit.type === "text" || fieldToEdit.field === "serialNumber") && !isQty) || !is2DecimalPlaces) {
      return (
        <FormControl
          error={error ? true : false}
          fullWidth
        >
          <TextField
            autoFocus
            required
            error={error ? true : false}
            margin="dense"
            id="updated-value"
            name="updatedValue"
            label="New Value"
            value={updatedValue}
            onChange={(e) => setUpdatedValue(e.target.value)}
            type={fieldToEdit.type}
            variant="standard"
          />
        </FormControl>
      )
    }
  }

  const handleSubmitEdit = async (field, value) => {
    let response = await handleMassInventoryUpdate(field, value)
    if (response?.name === "AxiosError") {
      let errorMsg = response.response.data.errors[0]
      setError(errorMsg)
    }
  }

  return (
    <Dialog
      open={shouldOpen}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault()
          await handleSubmitEdit(fieldToEdit.field, updatedValue)
        },
      }}
    >
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid
          container
          spacing={2}
          columns={12}
        >
          {/* TOP DIVIDER */}
          <Grid
            item
            xs={12}
            sx={{ paddingTop: "15px !important" }}
          >
            <Divider className="divider-title">Mass Inventory Update</Divider>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <DialogContentText
              sx={{ mb: 2 }}
              textAlign="left"
            >
              To modify a field value for the selected rows, please select the field you'd like to edit from the dropdown below and enter the new
              value.
            </DialogContentText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControl
              fullWidth
              sx={{ paddingBottom: 1 }}
            >
              <InputLabel
                id="form-field"
                required
              >
                Field To Edit
              </InputLabel>
              <Select
                autoFocus
                name="formField"
                required
                labelId="field-select-label"
                id="form-field"
                value={fieldToEdit}
                label="Form Field"
                margin="dense"
                variant="standard"
                onChange={customHandleChange}
                MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
              >
                <MenuItem
                  key="select-field"
                  value=""
                  disabled
                >
                  Select Field To Edit
                </MenuItem>
                {formFieldOptions}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
          >
            {fieldToEdit && determineInputToDisplay(fieldToEdit)}
            {error && <p className="error-msg">{error}</p>}
          </Grid>

          {/* BUTTON */}
          <Grid
            item
            xs={10}
          />
          <Grid
            item
            xs={2}
          >
            <DialogActions>
              <Button
                disabled={!fieldToEdit || !updatedValue}
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
