import * as React from "react"
import { useContext, useState } from "react"
import { Link } from "react-router-dom"

// ** MUI
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import UpdateIcon from "@mui/icons-material/Update"
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import MovingIcon from "@mui/icons-material/Moving"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import AttachFileIcon from "@mui/icons-material/AttachFile"
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos"

// ** Context
import AppContext from "../AppContext"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid"
import { CSVUploadModal } from "./CSVUploadModal"
import { RelocateItemsModal } from "./RelocateItemsModal"
import { DocumentAttachmentModal } from "./DocumentAttachmentModal"
import { ConsolidateInventoryModal } from "./ConsolidateInventoryModal"
import { getProgramName, permissionCheck } from "../utils"

export const CustomToolbar = ({
  selectedglobalprogramid,
  pathname,
  selectedrows,
  handletransferitems,
  clearselectedrows,
  csvOptions,
  excelOptions,
  toggleformdialog,
  handleinventoryupdate,
  handlependingchangesupdate,
  handlependingchangescount,
}) => {
  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={csvOptions}
          excelOptions={excelOptions}
        />
        <GridToolbarActionsButton
          selectedGlobalProgramId={selectedglobalprogramid}
          pathname={pathname}
          selectedRows={selectedrows}
          toggleFormDialog={toggleformdialog}
          handleTransferItems={handletransferitems}
          clearSelectedRows={clearselectedrows}
          handleInventoryUpdate={handleinventoryupdate}
          handlePendingChangesUpdate={handlependingchangesupdate}
          handlePendingChangesCount={handlependingchangescount}
        />
      </div>
      <GridToolbarQuickFilter sx={{ dislay: "flex", width: "30%", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }} />
    </GridToolbarContainer>
  )
}

export const GridToolbarActionsButton = ({
  selectedGlobalProgramId,
  pathname,
  selectedRows,
  handleTransferItems,
  clearSelectedRows,
  toggleFormDialog,
  handleInventoryUpdate,
  handlePendingChangesUpdate,
  handlePendingChangesCount,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isCsvModalOpen, setIsCsvModalOpen] = useState(false)
  const [isRelocateItemsModalOpen, setIsRelocateItemsModalOpen] = useState(false)
  const [isDocumentAttachmentModalOpen, setIsDocumentAttachmentModalOpen] = useState(false)
  const [isConsolidateInventoryModalOpen, setIsConsolidateInventoryModalOpen] = useState(false)
  const [documentRows, setDocumentRows] = useState([])
  let context = useContext(AppContext)
  const open = Boolean(anchorEl)
  const notPermitted = permissionCheck(["Government Customer", "Program Customer"], context.usersRoles)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDocumentRows = (rows) => {
    setDocumentRows(rows)
  }

  const toggleCSVUploadModal = (value) => {
    setIsCsvModalOpen(value)
  }

  const toggleRelocateItemsModal = (value) => {
    setIsRelocateItemsModalOpen(value)
  }

  const toggleDocumentAttachmentModal = (value) => {
    setIsDocumentAttachmentModalOpen(value)
  }

  const toggleConsolidateInventoryModal = (value) => {
    setIsConsolidateInventoryModalOpen(value)
  }

  return (
    <>
      {isCsvModalOpen && (
        <CSVUploadModal
          shouldOpen={true}
          handleClose={toggleCSVUploadModal}
          selectedGlobalProgramId={selectedGlobalProgramId}
          handleInventoryUpdate={handleInventoryUpdate}
        />
      )}
      {isRelocateItemsModalOpen && (
        <RelocateItemsModal
          shouldOpen={true}
          handleClose={toggleRelocateItemsModal}
          itemsToRelocate={selectedRows}
          clearSelectedRows={clearSelectedRows}
          selectedGlobalProgramId={selectedGlobalProgramId}
          handlePendingChangesUpdate={handlePendingChangesUpdate}
          handlePendingChangesCount={handlePendingChangesCount}
        />
      )}
      {isDocumentAttachmentModalOpen && (
        <DocumentAttachmentModal
          shouldOpen={true}
          handleClose={toggleDocumentAttachmentModal}
          selectedRowsIds={selectedRows.map((f) => f.id)}
          programName={getProgramName(selectedGlobalProgramId, context.allProgramsData)}
          documentRows={documentRows}
          handleDocumentRows={handleDocumentRows}
          clearSelectedRows={clearSelectedRows}
        />
      )}
      {isConsolidateInventoryModalOpen && (
        <ConsolidateInventoryModal
          shouldOpen={true}
          handleClose={toggleConsolidateInventoryModal}
          handleInventoryUpdate={handleInventoryUpdate}
          selectedRows={selectedRows}
          clearSelectedRows={clearSelectedRows}
        />
      )}
      {(context.isAdmin || !notPermitted) && (
        <div>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            startIcon={open ? <PlayArrowIcon sx={{ transform: "rotate(90deg)" }} /> : <PlayArrowIcon />}
          >
            Actions
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClick={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/* ATTACH DOCUMENTS OPTION */}
            {!context.isInventoryManager && (
              <MenuItem
                disabled={!selectedRows.length}
                onClick={() => {
                  toggleDocumentAttachmentModal(true)
                }}
              >
                <AttachFileIcon sx={{ marginRight: "10px" }} />
                Attach Document(s)
              </MenuItem>
            )}

            {/* CONSOLIDATE INVENTORY OPTION */}
            {!context.isInventoryManager && (
              <MenuItem
                disabled={!selectedRows.length || selectedRows.length <= 1 || selectedRows.length > 10}
                onClick={() => {
                  toggleConsolidateInventoryModal(true)
                }}
              >
                <AddToPhotosIcon sx={{ marginRight: "10px" }} />
                Consolidate Inventory
              </MenuItem>
            )}

            {/* IMPORT CSV OPTION */}
            {context.isAdmin && (
              <MenuItem onClick={handleClose}>
                <Link
                  to={`${pathname}/import`}
                  state={{ pathname: pathname, selectedProgramId: selectedGlobalProgramId }}
                >
                  <div style={{ display: "flex" }}>
                    <FileUploadIcon sx={{ marginRight: "10px" }} />
                    <span>Import CSV</span>
                  </div>
                </Link>
              </MenuItem>
            )}

            {/* MASS INVENTORY UPDATE OPTION */}
            {(context.isAdmin || context.isInventoryManager || context.isTL) && (
              <MenuItem
                disabled={!selectedRows.length}
                onClick={() => {
                  toggleFormDialog()
                }}
              >
                <FormatListNumberedIcon sx={{ marginRight: "10px" }} />
                Mass Inventory Update
              </MenuItem>
            )}

            {/* RELOCATE ITEMS OPTION */}
            {!context.isInventoryManager && (
              <MenuItem
                disabled={!selectedRows.length}
                onClick={() => {
                  toggleRelocateItemsModal(true)
                }}
              >
                <MovingIcon sx={{ marginRight: "10px" }} />
                Relocate Item(s)
              </MenuItem>
            )}

            {/* TRANSFER ITEMS OPTION */}
            {!context.isInventoryManager && (
              <MenuItem
                disabled={!selectedRows.length}
                onClick={() => {
                  handleTransferItems(selectedRows, "add")
                  clearSelectedRows()
                }}
              >
                <AssignmentReturnIcon sx={{ marginRight: "10px" }} />
                Transfer Item(s)
              </MenuItem>
            )}

            {/* UPDATE INVENTORY OPTION */}
            {!context.isInventoryManager && (
              <MenuItem
                onClick={() => {
                  toggleCSVUploadModal(true)
                }}
              >
                <UpdateIcon sx={{ marginRight: "10px" }} />
                Update Inventory
              </MenuItem>
            )}
          </Menu>
        </div>
      )}
    </>
  )
}
