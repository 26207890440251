import { useContext, useEffect, useState } from "react"

// ** Toastify
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// ** MUI
import { IconButton, Tooltip } from "@mui/material"
import DownloadIcon from "@mui/icons-material/Download"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"

// ** Styles
import "../components/styles/IssuedInventoryStyles.css"

// ** Custom
import IssuedItemsGrid from "../components/Issued/IssuedItemsGrid"
import FilterIssuedYearSelect from "../components/Issued/FilterIssuedYearSelect"
import Header from "../layout/Header"
import { createQuery, getProgramName, notify, triggerDownload } from "../utils"

const IssuedInventoryView = ({ leftMenuDrawerOpen, selectedGlobalProgramId }) => {
  let context = useContext(AppContext)
  const title = "Issued Items"
  const [issuedYear, setIssuedYear] = useState("")
  const [issuedData, setIssuedData] = useState("")
  let selectedProgramName = getProgramName(selectedGlobalProgramId, context.allProgramsData)

  useEffect(() => {
    if (issuedYear && selectedProgramName) {
      fetchIssuedRecords()
    }
  }, [issuedYear, selectedProgramName, selectedGlobalProgramId])

  const handleIssuedYearChange = (selection) => {
    setIssuedYear(selection)
  }

  const fetchIssuedRecords = async () => {
    try {
      let issuedResp

      // Get all sub-programs' issued records if a parent program is selected.
      let program = context["finalProgramsList"]?.find((program) => program.id === selectedGlobalProgramId)
      if (program.subPrograms) {
        let subNames = program.subPrograms.map((sub) => sub.name)
        let parentAndSubNames = [`${program.name}`, ...subNames]
        let query = createQuery("IssuedRecords", parentAndSubNames)
        issuedResp = await apiCalls.getRecords(`IssuedRecords?%24filter=(contains(issuedDate,'${issuedYear}'))%20and%20(${query})`)
      } else
        issuedResp = await apiCalls.getRecords(
          `IssuedRecords?%24filter=(contains(issuedDate,'${issuedYear}'))%20and%20programName%20eq%20%27${selectedProgramName}%27`
        )

      setIssuedData(issuedResp.data.value)
    } catch (error) {
      console.error(error)
      notify("error", `There was a problem loading the issued items.`)
    }
  }

  return (
    <>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
      <Header title={title} />
      <section className="issued-year-wrapper">
        <FilterIssuedYearSelect handleIssuedYearChange={handleIssuedYearChange} />
        <Tooltip
          title="Download Shipping Log"
          arrow
          placement="right"
        >
          <IconButton
            color="primary"
            onClick={async () => {
              await apiCalls.generateExcelFile("ShippingLog").then((response) => {
                const file = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                const url = window.URL.createObjectURL(file)
                triggerDownload(url, response.headers["content-disposition"].split('filename="')[1].split('";')[0])
                window.URL.revokeObjectURL(url)
              })
            }}
            sx={{ marginLeft: "5px" }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </section>
      {!issuedYear && <section className="issued-year-wrapper">Please select a year and program to view corresponding issued items.</section>}
      <div style={{ height: "65vh", width: "100%" }}>
        <IssuedItemsGrid issuedData={issuedData} />
      </div>
    </>
  )
}

export default IssuedInventoryView
