import * as React from "react"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

// ** MUI
import { Box, Button, Container, Divider, Grid, IconButton, Stepper, Step, StepLabel } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

// ** Toastify
import "react-toastify/dist/ReactToastify.css"

// ** Context
import AppContext from "../AppContext"

//** Styles
import "../components/styles/CustomFormStyles.css"

// ** Custom
import Header from "../layout/Header"
import ProgramSelectionForm from "../components/transfer process steps/ProgramSelectionForm"
import TransferItemsTable from "../components/transfer process steps/TransferItemsTable"
import ReviewTransferForm from "../components/transfer process steps/ReviewTransferForm"
import { SetTimeAndOffset } from "../utils"

const steps = ["Select Destination Program", "View and Set Quantity for Items", "Review Transfer"]

const TransferSummaryView = ({ transferItems, handleTransferItems, handleTransferRequestSubmit }) => {
  const context = useContext(AppContext)
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = React.useState(0)
  const [errors, setErrors] = useState("")
  const [disableSubmit, setDisableSubmit] = useState(false)

  // FIRST STEP VARIABLES
  const [startProgramId, setStartProgramId] = useState("")
  const [endProgramId, setEndProgramId] = useState("")
  const [initiator, setInitiator] = useState("")
  const initiationDate = SetTimeAndOffset(new Date().toISOString())

  // SECOND STEP VARIABLES
  const [tableItems, setTableItems] = useState("")
  const [rowSelectionModel, setRowSelectionModel] = useState([])

  // THIRD STEP VARIABLES
  const [startProgram, setStartProgram] = useState("")
  const [endProgram, setEndProgram] = useState("")
  const [finalRows, setFinalRows] = useState("")

  const title = "Transfer Summary"

  useEffect(() => {
    setInitiator(context.fullName)
  }, [initiator])

  useEffect(() => {
    setTableItems(
      transferItems
        .filter((f) => f.managementRecordArmyProgramId === startProgramId)
        .map((f) => {
          return { ...f, selectedQuantity: f.currentBalance }
        })
    )
    setStartProgram(context.allProgramsData?.filter((f) => f.id === startProgramId))
    setFinalRows([])
  }, [startProgramId])

  useEffect(() => {
    setEndProgram(context.allProgramsData?.filter((f) => f.id === endProgramId))
  }, [endProgramId])

  useEffect(() => {
    if (activeStep === 1) {
      setFinalRows(tableItems.filter((f) => rowSelectionModel.indexOf(f.id) !== -1))
    }
  }, [tableItems])

  const formatTransferItemPrograms = (transferItems) => {
    let transferItemProgramIds = [...transferItems]?.reduce((acc, item) => {
      if (!acc.includes(item.managementRecordArmyProgramId)) {
        acc.push(item.managementRecordArmyProgramId)
      }
      return acc
    }, [])

    const programDataToIterate = context.allProgramsData
    const transferPrograms = new Set(transferItemProgramIds)
    const transferProgramData = programDataToIterate?.filter((el) => transferPrograms.has(el.id.toString()))
    return transferProgramData
  }

  const handleNext = () => {
    // If on the last step -->
    if (activeStep === steps.length - 1) {
      setDisableSubmit(true)
      buildFormObject()
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSetValueFromStep = (e) => {
    switch (e.target.name) {
      case "startProgram":
        setStartProgramId(e.target.value)
        break
      case "endProgram":
        setEndProgramId(e.target.value)
        break
      default:
        break
    }
  }

  const handleValidate = (e) => {
    if (!e.target.value) {
      setErrors((prevState) => {
        return {
          ...prevState,
          [e.target.name]: true,
        }
      })
    } else {
      setErrors((prevState) => {
        return {
          ...prevState,
          [e.target.name]: false,
        }
      })
    }
  }

  const fetchCurrentStepView = () => {
    if (activeStep === 0) {
      return (
        <ProgramSelectionForm
          transferItems={formatTransferItemPrograms(transferItems)}
          startProgramId={startProgramId}
          endProgramId={endProgramId}
          handleSetValueFromStep={handleSetValueFromStep}
          errors={errors}
          handleValidate={handleValidate}
        />
      )
    } else if (activeStep === 1) {
      return (
        <TransferItemsTable
          tableItems={tableItems}
          handleTableItems={handleTableItems}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={onRowSelectionModelChange}
          handleTransferItems={handleTransferItems}
        />
      )
    } else {
      return (
        <ReviewTransferForm
          initiator={initiator}
          initiationDate={initiationDate}
          startProgramName={startProgram[0].name}
          endProgramName={endProgram[0].name}
          finalRows={finalRows}
          mode="review"
        />
      )
    }
  }

  // Determines wheter user can proceed to the next step.
  const proceedNextCheck = () => {
    if (activeStep === 0 && (!startProgramId || !endProgramId)) {
      return true
    } else if (activeStep === 1 && !rowSelectionModel.length) {
      return true
    } else if (disableSubmit) {
      return true
    } else {
      return false
    }
  }

  const handleTableItems = (updatedItems) => {
    setTableItems(updatedItems)
  }

  const onRowSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel)
    const selectedIDs = new Set(newRowSelectionModel)
    const selectedRowData = tableItems.filter((row) => selectedIDs.has(row.id.toString()))
    setFinalRows(selectedRowData)
  }

  const buildFormObject = () => {
    let form = {
      initiationDate: initiationDate,
      initiator: initiator,
      fromProgramId: startProgramId,
      fromProgramName: startProgram[0].name,
      toProgramId: endProgramId,
      toProgramName: endProgram[0].name,
      transferItems: finalRows.map((item) => {
        return {
          inventoryRecordId: item.id,
          nationalStockNumber: item.nationalStockNumber,
          partNumber: item.partNumber,
          serialNumber: item.serialNumber,
          nomenclature: item.nomenclature,
          unitOfIssue: item.unitOfIssue,
          quantity: item.selectedQuantity,
          conditionCode: item.conditionCode,
          unitPrice: item.unitPrice,
          totalCost: item.totalCost,
          location: item.location,
          propertyNumber: item.propertyNumber,
          warehouseId: item.managementRecordWarehouseId,
        }
      }),
    }
    handleTransferRequestSubmit(form)
  }

  return (
    <Container className="form-bg">
      <div className="back-btn-wrapper">
        <IconButton
          onClick={() => navigate(-1)}
          size="large"
        >
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
      </div>
      <div className="header-padding">
        <Header title={title} />
      </div>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps = {}
            const labelProps = {}
            return (
              <Step
                key={label}
                {...stepProps}
              >
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment />
        ) : (
          <React.Fragment>
            <Grid
              container
              columns={11}
            >
              <Grid
                item
                xs={11}
                sx={{ paddingTop: "25px !important" }}
              >
                <Divider />
              </Grid>
            </Grid>
            {fetchCurrentStepView()}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                onClick={handleNext}
                disabled={proceedNextCheck()}
              >
                {activeStep === steps.length - 1 ? "Submit" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
      {/* Space under 'BACK' and 'NEXT' buttons. */}
      <Box sx={{ height: "20px" }} />
    </Container>
  )
}

export default TransferSummaryView
