import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

// ** MUI
import { Button, Container, Divider, IconButton, Grid } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"

// ** Custom
import Header from "../layout/Header"
import ReviewTransferForm from "./transfer process steps/ReviewTransferForm"
import LoadingBackdrop, { notify, permissionCheck } from "../utils"

const EditTransferRequestForm = ({ leftMenuDrawerOpen, handleTransferRequestComplete }) => {
  let context = useContext(AppContext)
  const location = useLocation()
  const navigate = useNavigate()
  const notPermitted = permissionCheck(["Government Customer", "Program Customer", "Inventory Manager"], context.usersRoles)
  const transferRequest = location.state.params
  const [loaded, setLoaded] = useState(false)
  const [transferForm, setTransferForm] = useState("")
  const [tableItems, setTableItems] = useState("")
  const [viewOnly, setViewOnly] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const serialValues = ["", "NA", "N/A"]

  const subProgramsList = () => {
    if (context.finalProgramsList?.find((f) => f.name === transferRequest.toProgramName)?.subPrograms !== undefined) {
      let programList = []

      // Add parent program to list.
      let parentProgram = context.finalProgramsList?.find((f) => f.name === transferRequest.toProgramName)
      programList.push({ id: parentProgram.id, label: parentProgram.name })

      parentProgram?.subPrograms.map((el) => {
        programList.push({ id: el.id, label: el.name })
      })

      return programList
    } else return []
  }

  useEffect(() => {
    if (transferForm) setLoaded(true)
  }, [transferForm])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      let transfer = await apiCalls.getById(transferRequest.id, "TransferTransactions", "?%24expand=transferItems")
      setTransferForm(transfer.data)
      setTableItems(transfer.data.transferItems)

      if (transfer.data.status === "CANCELLED" || transfer.data.status === "COMPLETE") {
        setViewOnly(true)
        let loggedTransferItems = await apiCalls.getCollectionByTransactionId(transfer.data.id, "TransferTransaction", "TransferItems")
        setTableItems(
          loggedTransferItems.data.map((f) => {
            return f.transferItem
          })
        )
      }
    } catch (error) {
      console.error(error)
      notify("error", `There was a problem loading the transfer request information. Please try again.`)
    }
  }

  const handleTableItems = (newItems) => {
    setTableItems(newItems)
    handleDisableButton(newItems)
  }

  const handleDisableButton = (newItems) => {
    let locationCheck = newItems.filter((f) => f.newLocation === undefined || f.newLocation === "").length > 0 ? true : false
    let warehouseCheck = newItems.filter((f) => f.newWarehouseId === undefined).length > 0 ? true : false

    if (!locationCheck && !warehouseCheck) setButtonDisabled(false)
    else setButtonDisabled(true)
  }

  // Checks if a program/warehouse pairing exists, and if not, creates it.
  const handleManagementRecord = async (managementRecordsToCreate) => {
    let uniqueValues = managementRecordsToCreate.filter(
      (ele, ind) => ind === managementRecordsToCreate.findIndex((elem) => elem.programId === ele.programId && elem.warehouseId === ele.warehouseId)
    )
    return await Promise.all(
      uniqueValues.map(async (item) => {
        let response = await apiCalls.getManagementRecords(
          `?%24filter=WarehouseId%20eq%20${item.warehouseId}%20and%20ArmyProgramId%20eq%20${item.programId}`
        )
        if (response.data.value.length === 0) {
          let body = {
            armyProgramId: item.programId,
            warehouseId: item.warehouseId,
          }
          return await apiCalls.postRecord(body, "ManagementRecords")
        }
      })
    )
  }

  // Create a new record for the bulk items that requested a partial amount from the original record.
  const handleNewRecords = async (bulkItems) => {
    const newRecords = []

    bulkItems.forEach((item) => {
      tableItems.forEach((itemTwo) => {
        if (item.id === itemTwo.inventoryRecordId) {
          let newRecord = {
            ...item,
            quantity: itemTwo.quantity,
            totalCost: itemTwo.quantity * item.unitPrice,
            propertyNumber: "",
            radioFrequencyIdentificationNumber: "",
            comments: "",
            location: itemTwo.newLocation,
            managementRecordArmyProgramId: itemTwo.newProgramId !== undefined ? itemTwo.newProgramId : transferForm.toProgramId,
            managementRecordWarehouseId: itemTwo.newWarehouseId,
            calOriginalTotalCost: item.unitPrice * (item.quantity - itemTwo.quantity),
            calOriginalQuantity: item.quantity - itemTwo.quantity,
            originalInventoryRecordId: item.id,
          }

          // Remove fields that will be set in BE endpoint.
          delete newRecord.id
          delete newRecord.createdDate
          delete newRecord.modifiedDate

          newRecords.push(newRecord)
        }
      })
    })

    await Promise.all(
      newRecords.map(async (item) => {
        let response = await apiCalls.postRecord([item], "InventoryRecords")
        if (response.status === 201) {
          // Update the original record's quantity and total cost.
          let operation = "replace"
          let patchChanges = [
            { path: "Quantity", op: `${operation}`, value: `${item.calOriginalQuantity}` },
            { path: "TotalCost", op: `${operation}`, value: `${item.calOriginalTotalCost}` },
          ]

          return await apiCalls.patchRecordMultiple(item.originalInventoryRecordId, "InventoryRecords", patchChanges)
        }
      })
    ).then(async () => await handleTransferItems())
  }

  // Set the transfer items with new location values to the transfer request and mark transfer as 'COMPLETE', etc.
  const handleTransferItems = async () => {
    // Set transfer to 'COMPLETE', set 'Acceptor' value, and transfer items to include newLocation and newWarehouseId.
    const newTableItems = tableItems.map((item) => {
      let newItem = {
        ...item,
        location: item.newLocation,
        warehouseId: item.newWarehouseId,
        subProgramId: item.hasOwnProperty("newProgramId") ? item.newProgramId : null,
      }
      return newItem
    })

    let updateTransferForm = {
      ...transferForm,
      status: "COMPLETE",
      acceptor: context.username,
      transferItems: newTableItems,
    }

    await handleTransferRequestComplete(transferForm, updateTransferForm)
  }

  const handleEditTransferForm = async () => {
    setButtonDisabled(true)
    try {
      let bulkItems = []
      let managementRecordsToCreate = []

      // Update original inventory record for each transfer item.
      const originalRecords = await Promise.all(
        tableItems.map(async (item) => {
          managementRecordsToCreate.push({
            programId: item.hasOwnProperty("newProgramId") ? item.newProgramId : transferForm.toProgramId,
            warehouseId: item.newWarehouseId,
          })

          let originalRecord = await apiCalls.getById(item.inventoryRecordId, "InventoryRecords", "")
          return {
            ...originalRecord.data,
            newLocation: item.newLocation,
            newWarehouseId: item.newWarehouseId,
            newProgramId: item.newProgramId,
            newQuantity: item.quantity,
          }
        })
      )

      await handleManagementRecord(managementRecordsToCreate).then(async () => {
        await Promise.all(
          originalRecords.map(async (item) => {
            // Add all items to list that aren't serialized.
            if (serialValues.includes(item.serialNumber) && item.quantity !== 1 && item.quantity - item.newQuantity !== 0) bulkItems.push(item)
            else {
              let operation = "replace"
              // The serialized items (or bulk items that aren't being split) should just be transferred as is to the new program/warehouse.
              let patchChanges = [
                { path: "Location", op: `${operation}`, value: `${item.newLocation}` },
                {
                  path: "ManagementRecordArmyProgramId",
                  op: `${operation}`,
                  value: `${item.newProgramId !== undefined ? item.newProgramId : transferForm.toProgramId}`,
                },
                { path: "ManagementRecordWarehouseId", op: `${operation}`, value: `${item.newWarehouseId}` },
              ]

              return await apiCalls.patchRecordMultiple(item.id, "InventoryRecords", patchChanges)
            }
          })
        ).then(async () => await handleNewRecords(bulkItems))
      })
    } catch (error) {
      console.error(error)
      notify("error", "The transfer couldn't be completed. Please try again.")
    }
  }

  if (loaded) {
    return (
      <Container className="form-bg">
        <div className="back-btn-wrapper">
          <IconButton
            onClick={() => navigate(-1)}
            size="large"
          >
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        </div>
        <div className="header-padding">
          <Header title={`${transferRequest.documentNumber}: ${transferRequest.status}`} />
        </div>
        <Divider />
        <ReviewTransferForm
          initiator={transferRequest.initiator}
          initiationDate={transferRequest.initiationDate}
          startProgramName={transferRequest.fromProgramName}
          endProgramName={transferRequest.toProgramName}
          finalRows={tableItems}
          mode={viewOnly ? "overview" : "edit"}
          handleTableItems={handleTableItems}
          viewOnly={viewOnly}
          transfer={transferForm}
          subProgramsList={subProgramsList()}
        />
        {!viewOnly && !notPermitted && (
          <Grid
            container
            spacing={11}
            justify="center"
          >
            <Grid
              item
              xs={12}
              align="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button
                variant="contained"
                onClick={handleEditTransferForm}
                disabled={buttonDisabled}
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  width: "200px",
                  backgroundColor: "#105075",
                  border: "2px solid #646464",
                }}
              >
                Accept
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={11}
          sx={{ paddingTop: "20px !important" }}
        >
          <Divider />
        </Grid>
      </Container>
    )
  } else {
    return <LoadingBackdrop leftMenuDrawerOpen={leftMenuDrawerOpen} />
  }
}

export default EditTransferRequestForm
