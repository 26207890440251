import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"

export default function CSVUploadErrorTable({ rows }) {
  console.log("Rows inside CSV upload error table:", rows)
  const rowsToDisplay = [...rows]?.map((row, index) => {
    return (
      <TableRow key={`${index} + ${row.rowNumber}`}>
        <TableCell sx={{ padding: 0, fontSize: "11px", paddingLeft: "4px" }}>{row.rowNumber}</TableCell>
        <TableCell sx={{ padding: 0, fontSize: "11px" }}>{row.propertyName}</TableCell>
        <TableCell sx={{ padding: 0, fontSize: "11px", fontStyle: "italic" }}>{row.message}</TableCell>
        <TableCell sx={{ padding: 0, fontSize: "11px" }}>{row.attemptedValue}</TableCell>
      </TableRow>
    )
  })

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <TableContainer
        component={Paper}
        sx={{ border: "1px dotted red", width: "85%" }}
      >
        <Table
          sx={{ minWidth: 550 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "600", padding: 0, fontSize: "12px", color: "red", paddingLeft: "4px", paddingRight: "6px" }}>
                Row
              </TableCell>
              <TableCell sx={{ fontWeight: "600", padding: 0, fontSize: "12px", color: "red" }}>Property Name</TableCell>
              <TableCell sx={{ fontWeight: "600", padding: 0, fontSize: "12px", color: "red" }}>Error Message</TableCell>
              <TableCell sx={{ fontWeight: "600", padding: 0, fontSize: "12px", color: "red", lineHeight: 1.25 }}>Attempted Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rowsToDisplay}</TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
