import { useState } from "react"

// ** MUI
import { Box, Button, Divider, Grid, IconButton, Modal } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { style } from "../utils"

export const NextSignatureModal = ({ shouldOpen, handleConfirmSignature }) => {
  return (
    <Modal
      open={shouldOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* CLOSE ICON */}
        <Grid
          container
          spacing={2}
        >
          <IconButton onClick={() => handleConfirmSignature(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid
          container
          spacing={2}
          columns={11}
        >
          {/* TOP DIVIDER */}
          <Grid
            item
            xs={11}
            sx={{ paddingTop: "15px !important" }}
          >
            <Divider className="divider-title">Confirm Signature</Divider>
          </Grid>

          {/* TEXT */}
          <Grid
            item
            xs={11}
            sx={{ textAlign: "left" }}
          >
            By signing, you're confirming all necessary tasks have been completed and you agree your <b>username</b> will be applied for the{" "}
            <b>electronic signature</b>.
          </Grid>

          {/* BUTTON */}
          <Grid
            item
            xs={8}
          />
          <Grid
            item
            xs={3}
          >
            <Button
              variant="contained"
              onClick={() => handleConfirmSignature(true)}
            >
              Sign
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
