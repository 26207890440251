import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

// ** Toastify
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// ** MUI
import { IconButton, Stack, Tooltip } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Styles
import "../components/styles/AdditionalColumnStyles.css"

// ** Custom
import { useAuth } from "react-oidc-context"
import { CustomStripedGrid } from "../components/CustomStripedGrid"
import { EditUserModal } from "../components/EditUserModal"
import { CustomModal } from "../components/CustomModal"
import Header from "../layout/Header"
import LoadingBackdrop, { notify } from "../utils"

const UsersView = ({ usersUpdate, handleUsersUpdate, leftMenuDrawerOpen }) => {
  const auth = useAuth()
  const location = useLocation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState("")
  const [userInformation, setUserInformation] = useState("")
  const [userRoles, setUserRoles] = useState([])
  const [userPrograms, setUserPrograms] = useState([])
  const [userToDelete, setUserToDelete] = useState("")
  const [pathname, setPathname] = useState("")
  const title = "Users"

  useEffect(() => {
    fetchData()
  }, [usersUpdate])

  useEffect(() => {
    if (data) {
      setLoaded(true)
    }
  }, [data, usersUpdate])

  const fetchData = async () => {
    try {
      let users = await apiCalls.getKeycloakUsers()
      let filteredUsers = users.data.filter((item) => !item.attributes || !item.attributes.isDeveloper)
      setData(filteredUsers)
      setPathname(location.state?.path || window.location.pathname)
    } catch (error) {
      if (error.response?.status === 401) {
        auth.signinRedirect()
      } else {
        notify("error", "There was a problem loading the users. Please try again.")
      }
    }
  }

  const handleEditUser = async (row) => {
    try {
      setUserInformation(row)

      let uRoles = await apiCalls.getUsersRoles(row.id)
      // Handle if a user does not have any roles assigned.
      if (uRoles.data.length > 0) {
        setUserRoles(uRoles.data)
      } else {
        setUserRoles([])
      }

      let uPrograms = await apiCalls.getUsersPrograms(row.id)
      setUserPrograms(uPrograms.data)
      setIsModalOpen(true)
    } catch (error) {
      console.error("error", error)
      if (error.response?.status === 401) {
        auth.signinRedirect()
      } else {
        handleConfirmClose()
        notify("error", "The user's roles/programs could not be retrieved. Please try again.")
      }
    }
  }

  const handleConfirmClose = () => {
    setIsModalOpen(false)
  }

  const handleDeleteUser = async (response) => {
    if (response) {
      try {
        await apiCalls.deleteKeycloakUser(userToDelete.id)
        notify("success", `${userToDelete.username} was deleted.`)
        handleUsersUpdate()
      } catch (error) {
        console.error("Error: ", error)
        notify("error", "There was a problem deleting the user. Please try again.")
      }
    }
    setUserToDelete("")
    setIsDeleteModalOpen(false)
  }

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(true)
  }

  const columns = [
    { field: "username", headerName: "Username", minWidth: 160, flex: 1 },
    { field: "firstName", headerName: "First Name", minWidth: 160, flex: 1 },
    { field: "lastName", headerName: "Last Name", minWidth: 160, flex: 1 },
    { field: "email", headerName: "Email", minWidth: 180, flex: 1 },
    {
      field: "emailVerified",
      headerName: "Email Verified",
      minWidth: 120,
      sortable: false,
      disableExport: false,
      renderCell: (params) => {
        return <div style={{ marginLeft: 40 }}>{params.row.emailVerified ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}</div>
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      sortable: false,
      filerable: false,
      hideable: false,
      disableExport: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div>
            <Stack
              direction="row"
              spacing={-1.5}
            >
              <Tooltip
                title="Edit"
                arrow
              >
                <IconButton
                  color="default"
                  onClick={() => {
                    handleEditUser(params.row)
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Delete"
                arrow
              >
                <IconButton
                  color="error"
                  onClick={() => {
                    setUserToDelete(params.row)
                    toggleDeleteModal()
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </div>
        )
      },
    },
  ]

  const tableRows = () => {
    return data.map((user) => {
      return {
        id: user.id,
        createdTimestamp: user.createdTimestamp,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        emailVerified: user.emailVerified,
        enabled: user.enabled,
      }
    })
  }

  if (loaded) {
    return (
      <>
        {isModalOpen && (
          <EditUserModal
            shouldOpen={true}
            userInformation={userInformation}
            userRoles={userRoles}
            userPrograms={userPrograms}
            handleConfirmClose={handleConfirmClose}
            handleUsersUpdate={handleUsersUpdate}
          />
        )}
        {isDeleteModalOpen && (
          <CustomModal
            shouldOpen={true}
            handleConfirmDelete={handleDeleteUser}
            pathname={pathname}
            title="Confirm Delete"
            reference={userToDelete.username}
          />
        )}
        <Header title={title} />
        <div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
        <CustomStripedGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                enabled: false,
              },
            },
          }}
          data={tableRows()}
          columns={columns}
          title={title}
        />
      </>
    )
  } else {
    return <LoadingBackdrop leftMenuDrawerOpen={leftMenuDrawerOpen} />
  }
}

export default UsersView
