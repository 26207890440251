import { useContext, useEffect, useState } from "react"
import { useLocation, Link } from "react-router-dom"
import { useAuth } from "react-oidc-context"

// ** MUI
import { styled } from "@mui/material/styles"
import { Box, IconButton, Toolbar, List, CssBaseline, Typography, Badge, Tooltip } from "@mui/material"
import MuiDrawer from "@mui/material/Drawer"
import MuiAppBar from "@mui/material/AppBar"

// ** Context
import AppContext from "../AppContext"

// ** Custom
import { getPathname, permissionCheck } from "../utils"
import { themeVariables } from "../styleVariables"
import MenuItems from "../MenuItems"
import FilterProgramsSelect from "../components/FilterProgramsSelect"

// ** Icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import NotificationsIcon from "@mui/icons-material/Notifications"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import LogoutIcon from "@mui/icons-material/Logout"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn"
import InformationDialog from "../components/InformationDialog"

// ** Styles
// import "./styles/BaseLayoutStyles.css"

const drawerWidth = 300

// ** Menu
const MenuDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    borderRight: "none",
    color: themeVariables.app.palette.text,
    position: "relative",
    whiteSpace: "nowrap",
    width: 300,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
  "& .MuiSvgIcon-root": {
    color: "inherit",
  },
  "& .MuiListItemIcon-root": {
    color: "inherit",
  },
  // "& .MuiToolbar-root": {
  //   minHeight: "70px"
  // },
  "& .MuiToolbar-gutters": {
    backgroundColor: themeVariables.app.palette.green,
    // borderTopRightRadius: "5px",
    // borderBottomRightRadius: "5px",
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
}))

// ** Top Nav
const TopNav = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundImage: `linear-gradient(90deg, rgba(1,131,86,1) 0%, rgba(1,131,86,1) 65%, rgba(168,230,208,1) 100%)`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    margin: "0px",
    width: `calc(100% - ${drawerWidth + theme.spacing(7)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))
// ** End Top Nav ** //

// ** Page Content
const PageContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: `calc(100% - ${theme.spacing(7)})`,
  minWidth: `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create(["width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    margin: "0px",
    width: `calc(100% - ${drawerWidth}px)`,
    minWidth: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))
// ** End Page Content ** //

const BaseLayout = ({
  children,
  handleSelect,
  cartItems,
  pendingChangesCount,
  handleLeftMenuDrawer,
  transferItems,
  selectedGlobalProgramId,
  isInvLoading,
  isAlert,
}) => {
  let context = useContext(AppContext)
  const notPermitted = permissionCheck(["Government Customer", "Program Customer", "Inventory Manager"], context.usersRoles)
  const location = useLocation()
  const [selected, setSelected] = useState(getPathname(location.pathname))
  const [drawerOpen, setDrawerOpen] = useState(true)
  const auth = useAuth()
  const pagesNotToDisplay = !location.pathname.includes("new") && !location.pathname.includes("edit") && !location.pathname.includes("import")
  const isDropdownDisplayed =
    (selected === "inventory_records" && pagesNotToDisplay) ||
    selected === "dashboard" ||
    window.location.pathname === "/" ||
    selected === "issued" ||
    selected === "COSIS"

  useEffect(() => {
    setSelected(getPathname(location.pathname))
  }, [location.pathname])

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
    handleLeftMenuDrawer()
  }

  const MenuText = () => {
    return (
      <div style={{ marginLeft: "4rem" }}>
        <Typography variant="h2">{/* Navigate */}</Typography>
      </div>
    )
  }

  const handleLogout = () => {
    auth.removeUser()
    auth.signoutRedirect()
  }

  return (
    <>
      {/* Whole page */}
      <Box
        sx={{
          display: "flex",
          maxWidth: 1,
          minHeight: "85vh",
        }}
      >
        {isAlert ? (
          <InformationDialog
            type="newUser"
            handleLogout={handleLogout}
          />
        ) : (
          <>
            <CssBaseline />

            {/** ------------------***     [ ~ left side nav drawer ~ ]     ***------------------ **/}
            <MenuDrawer
              variant="permanent"
              open={drawerOpen}
            >
              <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pr: "24px", // keep right padding when drawer closed
                }}
              >
                <IconButton onClick={toggleDrawer}>
                  {/* <Avatar sx={{ bgcolor: "yellow" }} >AWIMS</Avatar> */}
                  {/* This will be replaced when appbar is added */}
                  <ChevronLeftIcon
                    sx={{
                      color: `${themeVariables.app.palette.text} !important`,
                      backgroundColor: themeVariables.app.palette.lightGreen,
                      borderRadius: "100%",
                      ...(!drawerOpen && { display: "none" }),
                    }}
                  />
                  <ChevronRightIcon
                    sx={{
                      color: `${themeVariables.app.palette.text} !important`,
                      backgroundColor: themeVariables.app.palette.lightGreen,
                      borderRadius: "100%",
                      ...(drawerOpen && { display: "none" }),
                    }}
                  />
                </IconButton>
                {drawerOpen && <MenuText />}
              </Toolbar>
              <List component="nav">
                {/** ------------------***     [ ~ nav menu ~ ]     ***------------------ **/}
                <MenuItems
                  selected={selected}
                  open={drawerOpen}
                  auth={auth}
                  pendingChangesCount={pendingChangesCount}
                />
              </List>
            </MenuDrawer>

            <PageContent open={drawerOpen}>
              <Box
                sx={{
                  maxWidth: "100%",
                  minHeight: "100vh",
                  // backgroundColor: themeVariables.app.palette.lightGreen,
                  backgroundColor: themeVariables.app.palette.bg,
                }}
              >
                {/** ------------------***     [ ~ top navigation ~ ]     ***------------------ **/}
                <TopNav
                  position="relative"
                  open={drawerOpen}
                  color="primary"
                >
                  <Toolbar
                    sx={{
                      pr: "24px", // keep right padding when drawer closed
                      justifyContent: "flex-end",
                    }}
                  >
                    {isDropdownDisplayed && (
                      <FilterProgramsSelect
                        handleSelect={handleSelect}
                        selectedGlobalProgramId={selectedGlobalProgramId}
                        isInvLoading={isInvLoading}
                        selected={selected}
                        // programsData={programsData}
                      />
                    )}
                    <section className="flex tnIcons">
                      {!context.isInventoryManager && (
                        <Link to={`/request_summary`}>
                          <Tooltip
                            title="Request Items"
                            arrow
                          >
                            <IconButton>
                              <Badge
                                badgeContent={cartItems.length}
                                color="primary"
                              >
                                <ShoppingCartIcon sx={{ color: "black" }} />
                              </Badge>
                            </IconButton>
                          </Tooltip>
                        </Link>
                      )}
                      {!notPermitted && (
                        <Link to={"/transfer_summary"}>
                          <Tooltip
                            title="Transfer Items"
                            arrow
                          >
                            <IconButton>
                              <Badge
                                badgeContent={transferItems.length}
                                color="primary"
                              >
                                <AssignmentReturnIcon sx={{ color: "black" }} />
                              </Badge>
                            </IconButton>
                          </Tooltip>
                        </Link>
                      )}
                      {/* <IconButton>
                        <Badge
                          badgeContent={2}
                          color="primary"
                        >
                          <NotificationsIcon sx={{ color: "black" }} />
                        </Badge>
                      </IconButton> */}
                      <Tooltip
                        title="Profile"
                        arrow
                      >
                        <IconButton
                          color="inherit"
                          onClick={() => window.location.replace(`${auth.settings.authority}/account/#/`)}
                        >
                          <Badge
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <AccountCircleIcon />
                            {auth.user && (
                              <p
                                style={{
                                  fontSize: "14px",
                                  margin: 0,
                                  paddingTop: "4px",
                                  paddingLeft: "2px",
                                  fontFamily: "Merriweather",
                                  fontWeight: "700",
                                }}
                              >
                                {auth.user?.profile.preferred_username}
                              </p>
                            )}
                          </Badge>
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Logout"
                        arrow
                      >
                        <IconButton
                          color="inherit"
                          onClick={() => handleLogout()}
                        >
                          <Badge>
                            <LogoutIcon />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                    </section>
                  </Toolbar>
                </TopNav>

                <Box
                  sx={{
                    padding: "2rem",
                    paddingTop: "1rem",
                  }}
                >
                  {children}
                </Box>
              </Box>
            </PageContent>
          </>
        )}
      </Box>
    </>
  )
}

export default BaseLayout
