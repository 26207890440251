// ** MUI
import { Box, Button, Grid } from "@mui/material"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import WarningIcon from "@mui/icons-material/Warning"

// ** Custom
import { style } from "../utils"

const InformationDialog = ({ type, handleLogout }) => {
  const isFailedLogin = type === "failedLogin"

  return (
    <Box sx={style}>
      <Grid
        container
        spacing={2}
        columns={11}
      >
        <Grid
          item
          xs={11}
        >
          {isFailedLogin ? (
            <HighlightOffIcon
              color="error"
              style={{ fontSize: 50 }}
            />
          ) : (
            <WarningIcon
              color="warning"
              style={{ fontSize: 50 }}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={11}
      >
        {isFailedLogin
          ? "Sorry, we weren't able to log you in. Please contact an administrator for assistance."
          : "Before you can start using AWIMS, you must notify an administrator so they can assign you to your roles and programs."}
      </Grid>
      {!isFailedLogin && (
        <Grid
          item
          sx={{ marginTop: "15px" }}
        >
          <Button
            variant="contained"
            onClick={() => handleLogout()}
          >
            LOGOUT
          </Button>
        </Grid>
      )}
    </Box>
  )
}

export default InformationDialog
